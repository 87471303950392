import { Link } from 'gatsby';
import React from 'react'

interface TagProps {
  tags: GatsbyTypes.Maybe<{
    readonly nodes: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<Pick<GatsbyTypes.WpTag, "uri" | "name">>[]>;
  }>
}

const Tags = (props: TagProps) => {

  if (props.tags?.nodes?.length! < 1) return null

  return (
    <p>
      Tags: {` `}
      {props.tags?.nodes?.map((tag, i, arr) => <span>
        <Link to={tag?.uri!}>{tag?.name}</Link>{arr.length - 1 === i ? `` : `, `}
      </span>)}
    </p>
  )
}

export default Tags