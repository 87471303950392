import React from "react"
import { Link, graphql } from "gatsby"
import type { PageProps } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"


// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"
import FeaturedImage from "../components/Content/FeaturedImage"
import AcfProjectImage from "../components/Content/AcfProjectImage"
import Tags from "../components/Content/Tags"

type ProjectPostProps = {
  data: GatsbyTypes.ProjectPostByIdQuery
}

const ProjectPostTemplate = ({ data }: ProjectPostProps) => {
  const { project, allWpProject } = data
  const similar = allWpProject?.edges

  const imagesAbove = project?.acf?.projectImagesAbove?.length
  const imagesBelow = project?.acf?.projectImagesBelow?.length
  const featuredImage = project?.featuredImage

  return (
    <Layout pageTitle="< Work">
      <Seo title={project?.title}
        meta={[
          { name: `keywords`, content: project?.seo?.focuskw || `` },
          { property: `og:title`, content: project?.seo?.opengraphTitle || project?.title! },
          { property: `og:description`, content: project?.seo?.opengraphDescription! },
          { property: `og:type`, content: `article` },
          { property: `og:author`, content: project?.seo?.opengraphAuthor || `Neha Kale` },
          { property: `og:image`, content: project?.seo?.opengraphImage?.sourceUrl || `` },
          { property: `og:image:alt`, content: project?.seo?.opengraphImage?.altText || `` },
          { property: `og:url`, content: project?.seo?.canonical || `` },
          { name: `twitter:card`, content: `summary` },
          { name: `twitter:title`, content: project?.seo?.twitterTitle || project?.title! },
          { name: `twitter:description`, content: project?.seo?.twitterDescription! },
          { name: `twitter:image`, content: project?.seo?.twitterImage?.sourceUrl! },
        ]} />
      <div className="mb-32">
        <article
          className="project-post"
          itemScope
          itemType="http://schema.org/Article">

          <header className="mb-5">

            <FeaturedImage image={project?.featuredImage?.image} />

            {imagesAbove ? <>
              { project?.acf?.projectImagesAbove?.map((aboveImage) => {
                if (!aboveImage?.image || aboveImage?.image?.id === featuredImage?.image?.id) {
                  // Skip duplicating the featured image
                  return null
                }
                return (
                  <AcfProjectImage key={aboveImage?.image?.id} image={aboveImage?.image} />
                  )})}
                <hr className="block mt-5 mb-12 w-16 border-black" />
              </> : null
            }

            <h1 itemProp="headline" className="font-bold text-lg mb-2 tracking-wide text-gray-700 not-italic">
              {parse(project?.title!)}
            </h1>
            <p className="italic text-base">{project?.date}</p>
          </header>

          {!!project?.content! && (
            <section itemProp="articleBody">{parse(project?.content!)}</section>
          )}

          {imagesBelow && <>
            {project?.acf?.projectImagesBelow?.map((belowImage) => {
              if (!belowImage?.image) return null
              return (
                <AcfProjectImage key={belowImage?.image?.id} image={belowImage?.image} lazy={true} />
              )})}
            </>
          }

          <p className="italic">Posted on {project?.date}</p>

          <Tags tags={project?.tags} />

          <hr className="block mt-6 mb-12 w-16 border-black" />

          {similar && <>
            <h3 className="font-bold mb-5">Similar: </h3>
            <ul>
              {similar.map((node, index) => {
                return <li className="mb-5" key={index}>
                  <Link to={node?.node?.uri!}>{node.node.title!}</Link>
                </li>
              })}
            </ul>
          </>}

        </article>
      </div>
    </Layout>
  )
}

export default ProjectPostTemplate

export const pageQuery = graphql`
  query ProjectPostById($id: String!, $categoryId: String!) {
    project: wpProject(id: {eq: $id}) {
      id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      ...ProjectFeaturedImage
      acf: projects {
        projectDate
        projectDescription
        projectImagesAbove {
          image {
            id
            gatsbyImage(layout: FULL_WIDTH, width: 664, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
            description
            caption
            altText
          }
        }
        projectImagesBelow {
          image {
            id
            gatsbyImage(layout: FULL_WIDTH, width: 664, placeholder: BLURRED, formats: [AUTO, WEBP])
            title
            description
            caption
            altText
          }
        }
      }
      tags {
        nodes {
          uri
          name
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        opengraphAuthor
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
        }
      }
    }
    allWpProject(
      limit: 5
      filter: {categories: {nodes: {elemMatch: {id: {eq: $categoryId}}}}, id: {ne: $id}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          uri
        }
      }
    }
  }

`
