import React from 'react'
import parse from "html-react-parser"
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

interface AcfProjectImageProps {
  image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'id' | 'gatsbyImage' | 'description' | 'title' | 'altText' | 'caption'>>
  lazy?: boolean,
}

const AcfProjectImage = ({ image, lazy }: AcfProjectImageProps) => {

  if (!image?.gatsbyImage) return null

  return (
    <figure
      className="h-auto w-full mb-6">
      <GatsbyImage
        alt={image?.altText || image?.description || ``}
        image={image?.gatsbyImage!}
        loading={lazy ? "lazy" : "eager"}
      />
      <figcaption className="flex justify-end text-xs italic text-gray-500 my-3">
        {parse(image?.caption || ``)}
      </figcaption>
    </figure>
  )
}

export default AcfProjectImage