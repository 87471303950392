import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from "html-react-parser"

interface  FeaturedImageProps {
  image: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'id' | 'gatsbyImage' | 'description' | 'title' | 'altText' | 'caption'>>
}

const FeaturedImage = ({ image }: FeaturedImageProps) => {

  if (!image?.gatsbyImage) return null

  return (
    <figure
      className="featured-image h-auto w-full mb-6">
      <GatsbyImage alt={image?.altText || image?.description || ``}
        image={image?.gatsbyImage!}
        loading="eager"
      />
      <figcaption className="flex justify-end text-xs italic text-gray-500 my-3">
        {parse(image?.caption || ``)}
      </figcaption>
    </figure>
  )
}

export default FeaturedImage

export const projectQuery = graphql`
  fragment ProjectFeaturedImage on WpProject {
    featuredImage {
      image: node {
        id
        gatsbyImage(width: 644, placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, WEBP])
        description
        title
        altText
        caption
      }
    }
  }
`

export const postQuery = graphql`
  fragment PostFeaturedImage on WpPost {
    featuredImage {
      image: node {
        id
        gatsbyImage(width: 644, placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, WEBP])
        description
        title
        altText
        caption
      }
    }
  }
`